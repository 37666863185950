import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
// import { Button, Form, Input } from "antd";
import Fade from "react-reveal/Fade";
import Accordion from "react-bootstrap/Accordion";


function Footer() {
  const location = useLocation();

  // const validateMessages = {
  //   required: "${label} is required!",
  //   types: {
  //     email: "${label} is not a valid email!",
  //     number: "${label} is not a valid number!",
  //   },
  //   number: {
  //     range: "${label} must be between ${min} and ${max}",
  //   },
  // };

  // const onFinish = (values) => {
  //   console.log(values);
  // };

  return (
    <Fragment>
      <div className="container myfooter hidetab">
        <div className="row">
          <Fade left delay={500}>
            <div className="col-sm-3 moreWidth">
              <img
                // src={Logo}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/Logo.png`}
                alt="logo"
                className="footerlogo"
              />
              <p>
                Salaam Family Takaful Limited, is an Unlisted Public Limited
                Company incorporated in Pakistan{" "}
              </p>
              <p>
                6th floor, Business Centre, Plot No 19-1-A, Block -6.
                P.E.C.H.S., Shahrah-e-Faisal, Karachi, Pakistan
              </p>

              <Link to="tel:021111875111">UAN: 021-111-875-111</Link>
              <Link to="mailto:support@salaamlifetakaful.com">
                cs@salaamlifetakaful.com
              </Link>
            </div>
          </Fade>

          <Fade left delay={500}>
            <div className="col-sm-3 lessWidth">
              <h3>Quick Links</h3>
              <ul>
                {/* <li>
                  <Link
                    to="/whoweare#shariah"
                    onClick={(e) => {
                      const element = document.getElementById("shariah");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Shariah Advisor
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/governance#legal"
                    onClick={(e) => {
                      const element = document.getElementById("legal");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Legal Advisor
                  </Link>
                </li>

                <li>
                  <Link
                    to="/policy"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/careers"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right delay={500}>
            <div
              className="col-sm-3 supportLinks lessWidth"
              style={{ paddingTop: "36px" }}
            >
              <h3></h3>
              <ul>
                <li>
                  <Link
                    to="/fund-prices"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    Fund Prices
                  </Link>
                </li>
                <li>
                  <Link
                    to="/past-prices"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    Past Prices
                  </Link>
                </li>

                <li>
                  <Link
                    to="/complain-box"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    Complaint Form
                  </Link>
                </li>

                {/* <li>
                  <Link to="https://online.salaamtakaful.com/e-verify.aspx" target="_blank">Certificate Validation</Link>
                </li> */}
                {/* <li>
                  <Link
                    to="https://sdms.secp.gov.pk/"
                    target="_blank"
                  >
                    SECP's Service Desk
                  </Link>
                </li> */}
              </ul>
            </div>
          </Fade>

          <Fade right delay={500}>
            <div className="col-sm-3 last normalWidth">
              {/* <h3>Subscribe</h3>
              <p>
                Get the latest report about the insurance and company profile
              </p>

              <Form
                name="nest-messages"
                onFinish={onFinish}
                style={{ maxWidth: 600 }}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["user", "email"]}
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail Address" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {" "}
                    <img
                      // src={Arrow}

                      src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/formarrow.png`}
                      alt=""
                    />{" "}
                  </Button>
                </Form.Item>
              </Form> */}

              <div className="secp-img">
                <a href="https://sdms.secp.gov.pk/">
                  <img
                    src="https://www.salaamtakaful.com/assets/images/SECPSDMF.png"
                    alt="secp"
                  />
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      <div className="container copyright desktop">
        <div className="row">
          <p>© Copyright 2024 SALAAM FAMILY TAKAFUL LIMITED | Powered by <a href="https://avancerasolution.com/" target="_blank" rel="noreferrer">AvanceraSolutions</a></p>
        </div>
      </div>

      {/* mobile footer */}

      <div className="container myfooter showtab">
        <div className="row">
          <Fade up delay={500}>
            <div className="col-sm-12 textcentr">
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/Logo.png`}
                alt="logo"
                className="footerlogo"
              />
              <p>
                Salaam Family Takaful Limited, is an Unlisted Public Limited
                Company incorporated in Pakistan{" "}
              </p>
              <p>
                6th floor, Business Centre,Plot No 19-1-A, Block -6. P.E.C.H.S.,
                Shahrah-e-Faisal, Karachi Pakistan
              </p>

              <Link to="tel:021111875111">UAN: 021-111-875-111</Link>
              <Link to="mailto:support@salaamlifetakaful.com">
                cs@salaamlifetakaful.com
              </Link>
            </div>
          </Fade>

          <div className="col-sm-12">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Quick Links</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <Link
                        to="/governance#legal"
                        onClick={(e) => {
                          const element = document.getElementById("legal");
                          if (element) {
                            element.scrollIntoView({ behavior: "smooth" });
                          }
                        }}
                      >
                        Legal Advisor
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/policy"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/careers"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fund-prices"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Fund Prices
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/past-prices"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Past Prices
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/complain-box"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Complaint Form
                      </Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                {/* <Accordion.Header>Support</Accordion.Header> */}
                <Accordion.Body>
                  <ul>
                    <li>
                      <Link
                        to="/fund-prices"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Fund Prices
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/complain-box"
                        onClick={() => {
                          document.documentElement.scrollTop = 0;
                          document.body.scrollTop = 0;
                        }}
                      >
                        Complain Form
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="https://online.salaamtakaful.com/e-verify.aspx" target="_blank">Certificate Validation</Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="https://sdms.secp.gov.pk/"
                        target="_blank"
                      >
                        SECP's Service Desk
                      </Link>
                    </li> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="2">
                <Accordion.Header>Subscribe</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Get the latest report about the insurance and company
                    profile
                  </p>

                  <Form
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                    validateMessages={validateMessages}
                  >
                    <Form.Item
                      name={["user", "email"]}
                      rules={[{ type: "email", required: true }]}
                    >
                      <Input placeholder="E-mail Address" />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {" "}
                        <img
                          //  src={Arrow}

                          src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/formarrow.png`}
                          alt=""
                        />
                      </Button>
                    </Form.Item>
                  </Form>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </div>
      </div>

      <div className="container copyright mobile">
        <div className="row">
          <p>© Copyright 2024 SALAAM FAMILY TAKAFUL LIMITED</p>
          <p>Powered by <a href="https://avancerasolution.com/" target="_blank" rel="noreferrer">AvanceraSolutions</a></p>
        </div>
      </div>

      {location.pathname === "/buynow" ? (
        <></>
      ) : (
        <div className="container foots banner">
          <div className="row">
            <div className="col-sm-6 buttonscta bttnsss">
              <Fade right delay={800}>
                {/* <Link
                  to="/buynow"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                > */}
                <a
                  className="buy"
                  href="https://portals.salaamlifetakaful.com/"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    //  src={buyn}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/buyn.png`}
                    width="40"
                    alt=""
                    height="40"
                  />
                  Buy Now
                </a>
                {/* </Link> */}
              </Fade>
              {/* <Fade left delay={800}>
                <Link to="/login" className="call asd">
                  Customer Login
                </Link>
              </Fade> */}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Footer;
